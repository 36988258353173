.g-recaptcha>div>div {
    margin: 20px auto !important;
    text-align: center;
    width: auto !important;
    height: auto !important;
}

.disabledCaptcha {
    pointer-events: none;
    opacity: 0.8;
    filter: blur(1px) grayscale(100%);
    transition: filter 0.4s, opacity 0.4s;
}

.disabledCaptcha.activatedCaptcha {
    pointer-events: auto;
    opacity: 1;
    filter: none
}

.content {
    background-color: white;
}

.client-header {
    border-bottom: 1px solid #a6aeb5;
    width: 100%;
}

.pagdividido-green {
    color: #1ec285;
}

.pagdividido-pink {
    color: #10d37c;
}

.pagdividido-blue {
    color: #086fa3;
}

.pagdividido-label {
    color: #e02;
}

.progress-text {
    font-size: 0.75rem;
}

table tbody tr {
    line-height: 5px;
    min-height: 5px;
    height: 5px;
}

.col,
.col-xs-0,
.col-sm-0,
.col-md-0,
.col-lg-0 {
    flex: 0 0 0;
    max-width: 0;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.form-label {
    margin-top: 15px;
    margin-bottom: -13px;
    margin-left: 6px;
    color: #c4c4c4;
}

.ReactModal__Body--open {
    overflow-y: hidden;
}

.welcome-container {
    max-width: 400px !important;
    width: 400px;
    border-radius: 20px;
    background-color: rgb(242, 242, 242);
    color: black;
    min-height: 50px !important;
}

.wrapper {
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width: 719px) {
    .offer-container {
        max-width: 400px !important;
    }
}

@media (min-width: 720px) {
    .offer-container {
        width: 800px !important;
        margin-left: -50% !important;
    }
}

.form-container:not(.offer-container) {
    max-width: 500px !important;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.contract-container {
    background: #FFFFFF;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.iframe-container {
    border: 0px;
    min-height: 800px;
}

.close-modal-button {
    border-width: 0;
    border-radius: 10px;
    height: 50px;
    line-height: 50px;
    width: 150px;
    float: left;
    background-color: #e7e8e8;
    color: black;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

@media (min-width: 728px) {
    .previous-button {
        border-width: 0;
        border-radius: 10px;
        height: 60px;
        line-height: 60px;
        width: 400px;
        margin-top: 40px;
        margin-right: 20px;
        float: left;
        background-color: #e7e8e8;
        color: black;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 15px;
    }
    .next-button {
        border-width: 0;
        border-radius: 10px;
        height: 60px;
        line-height: 60px;
        width: 400px;
        margin-top: 20px;
        background-color: #10d37c;
        cursor: pointer;
    }
    .next-button::before {
        font-family: fontAwesome;
        content: "\f023\00a0";
        margin-left: 10px;
        font-size: 30px;
        color: #086fa3;
    }
    .resend-button {
        border-width: 0;
        border-radius: 10px;
        height: 60px;
        line-height: 60px;
        width: 400px;
        margin-top: 20px;
        background-color: #60698a;
        cursor: pointer;
    }
    .resend-button::before {
        font-family: fontAwesome;
        content: "\f01e\00a0";
        margin-left: 10px;
        font-size: 30px;
        color: #ffffff;
    }
    form select {
        height: 50px;
        border-radius: 10px;
        border-color: #c4c4c4;
        border-width: 2px;
        border-style: solid;
        width: 400px;
        padding-left: 15px;
        margin-top: 15px;
    }
    .decision-status {
        font-weight: 600;
        font-size: 28px;
        text-align: center;
    }
}

.send-button {
    border-width: 0;
    border-radius: 10px;
    height: 60px;
    line-height: 60px;
    width: 100% !important;
    margin-top: 20px;
    background-color: #10d37c;
    color: #384268;
    cursor: pointer;
}

.send-button:hover {
    background-color: #384268;
    color: #10d37c;
}

.send-button-client {
    border-width: 0;
    border-radius: 10px;
    height: 60px;
    line-height: 60px;
    width: 100% !important;
    margin-top: 20px;
    background-color: #10d37c;
    color: white;
}

.send-button-client:hover {
    background-color: #777777;
    color: white;
}

.send-button-client:disabled {
    background-color: #777777;
    color: white;
    cursor: progress;
}

@media (max-width: 727px) {
    .previous-button {
        border-width: 0;
        border-radius: 10px;
        height: 60px;
        line-height: 60px;
        width: 100% !important;
        margin-top: 40px;
        margin-right: 20px;
        float: left;
        background-color: #e7e8e8;
        color: black;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 15px;
    }
    .next-button {
        border-width: 0;
        border-radius: 10px;
        height: 60px;
        line-height: 60px;
        width: 100% !important;
        margin-top: 20px;
        background-color: #10d37c;
        cursor: pointer;
    }
    .next-button::before {
        font-family: fontAwesome;
        content: "\f023\00a0";
        margin-left: 10px;
        font-size: 30px;
        color: #086fa3;
    }
    .resend-button {
        border-width: 0;
        border-radius: 10px;
        height: 60px;
        line-height: 60px;
        width: 100% !important;
        margin-top: 20px;
        background-color: #60698a;
        cursor: pointer;
    }
    .resend-button::before {
        font-family: fontAwesome;
        content: "\f01e\00a0";
        margin-left: 10px;
        font-size: 30px;
        color: #ffffff;
    }
    form select {
        height: 50px;
        border-radius: 10px;
        border-color: #c4c4c4;
        border-width: 2px;
        border-style: solid;
        width: 100% !important;
        padding-left: 15px;
        margin-top: 15px;
    }
    .decision-status {
        transform: translateY(+50%);
        font-size: 1rem;
        text-align: center;
    }
}

.next-button i::before {
    margin-left: 0;
    font-size: 25px;
    color: #179c6a;
}

.send-button span {
    font-family: 'Roboto', sans-serif;
    position: relative;
    font-size: 20px;
    font-weight: bold;
}

.send-button-client span {
    font-family: 'Roboto', sans-serif;
    position: relative;
    font-size: 20px;
    font-weight: bold;
}

.next-button span {
    font-family: 'Roboto', sans-serif;
    position: relative;
    left: -17px;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
}

.resend-button span {
    font-family: 'Roboto', sans-serif;
    position: relative;
    left: -17px;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
}

.offer-card {
    background-color: #e7e8e8;
    border-width: 0;
    border-radius: 20px;
    width: 300px;
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
}

.offer-button {
    border-width: 0;
    border-radius: 10px;
    height: 60px;
    line-height: 60px;
    width: 80% !important;
    margin-top: 40px;
    background-color: #1ec285;
    cursor: pointer;
}

.offer-button span {
    position: relative;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
}

.offer-line {
    border-bottom: 5px;
    border-bottom-style: ridge;
    border-color: #ffffff;
}

.label-wrapper>.hover-label {
    position: relative;
    color: #999;
    top: -2.3rem;
    left: 1rem;
    transition: all .1s ease-in-out;
    cursor: text;
}

.label-wrapper> :focus+.hover-label {
    color: #10d37c;
    padding-left: 10px;
    padding-right: 10px;
    top: -4rem;
    background: white;
}

.noOutline {
    border: none;
    margin-top: 0;
    z-index: 1000;
}

.indexedStep {
    color: #000000;
    width: 25px;
    height: 25px;
    font-size: 15px;
    background-color: #7d7d7d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    white-space: nowrap;
}

.client-header .row {
    margin-right: 70px;
}

.indexedStep.accomplished {
    background-color: #10d37c;
}

.header-wrapper {
    top: 0;
    width: 100vw;
    position: fixed;
    background-color: white;
    z-index: 1000;
    height: 107px;
}

.form-border {
    border-left: #a6aeb5;
    border-style: solid;
    border-width: 0 0 0 1px;
}

.new-btn-default {
    cursor: pointer;
    justify-content: center;
    text-decoration: none;
    vertical-align: top;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 40px;
    width: 160px;
    background: #ffb100;
    border-radius: 2rem;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
}

.btn-next {
    float: right;
}

.new-btn-back {
    float: left;
    position: relative;
    left: 0;
    top: 0;
    margin: 0;
    border: 2px solid #313131;
    border-radius: 20px;
    background: #fff;
    line-height: 25px;
    color: #313131;
}

.checkbox input[type="checkbox"] {}

select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
}


/* For IE10 */

select::-ms-expand {
    display: none;
}

.form-group label {
    font-weight: 600;
    float: left;
    padding-bottom: 5px;
    display: block;
    margin-bottom: 0;
    text-transform: uppercase;
}

.form-group small {
    margin-top: 0;
}

.form-group input {
    font-weight: 700;
    font-size: 22px;
}

.input-lg {
    font-weight: 700;
    height: 46px;
    padding: 10px 40px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 5px;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

h6 {
    font-size: 0.75rem !important;
    font-weight: 300 !important;
}

h4 {
    font-size: 1rem !important;
    font-weight: 400 !important;
}

.term-acceptance {
    font-size: 0.8rem !important;
}

.term-acceptance a {
    color: #1BC37F;
}

.term-acceptance a:hover {
    color: #168f5f;
    text-decoration: none;
}

.form-container-title {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
}

.form-container-amount {
    font-size: 2.4rem;
    font-weight: 700;
}

.text-footnote {
    font-size: 1rem !important;
    font-weight: 400 !important;
}

.text-title {
    font-size: 1, 5rem !important;
    font-weight: 600 !important;
}

@media (max-width: 719px) {
    h2 {
        font-size: 1rem !important;
    }
}