body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: contain;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color:#000;
  line-height:1.2
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
