.footer-container {
    border-radius: 20px;
    background-color: RGB(242,242,242);
    color: black;
}

.slider-container {
    max-width: 450px;
    
}

.text-pink {
    color: #384268;
}

.text-blue {
    color: RGB(51,141,205);
}

h5 {
    font-size: 0.75rem;
    color: #383838 !important;
    font-weight: 300 !important;
}

h4 {
    font-weight: 600;
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 60px !important;
    height: 60px !important;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-slide img {
    display: inline !important;
}

@media (max-width: 699px) {
    .Footer {
        display: none;
    }
}

