.toast-container {
    background-color: RGB(242,242,242);
    color: black;
 }

.img-container {
    text-align: center; /* ensures the image is always in the h-middle */
    overflow: hidden; /* hide the cropped portion */
    padding: 0;
}
    .img-container img {
        max-height: 100%;
        max-width: 100%;
        position: relative;
        left: 50%;
        transform: translate(-50%,0)
    }

.img-partner-container img {
    max-height: 100%;
    max-width: 100%;
    position: relative;
    text-align: center; /* ensures the image is always in the h-middle */
    overflow: hidden; /* hide the cropped portion */
    padding: 0;
}

.Toastify__toast--default {
    background-color: RGB(242,242,242) !important;
    color: black !important;
}