.label-wrapper > .hover-label {
    position: relative;
    color: #999;
    top: -2.3rem;
    left: 1rem;
    transition: all .1s ease-in-out;
    cursor: text;
}

.label-wrapper > :focus + .hover-label {
    color: #10d37c;
    padding-left: 10px;
    padding-right: 10px;
    top: -4rem;
    background: white;
}

.form-label {
    margin-top: 15px;
    margin-bottom: -13px;
    margin-left: 6px;
    color: #c4c4c4;
}

@media (max-width: 727px) {
    .simulation-features {
        max-width: 100%;
        border-radius: 20px;
        background-color: RGB(242,242,242);
        color: black;
    }
    .simulation-box {
        max-width: 100%;
    }
    .advanceButton {
        background-color: RGB(51,141,205);
        color: #fff;
        font-weight: 500;
        display: inline-block;
        border: none;
        height: 50px;
        width: 100%;
        line-height: 46px;
        text-align: center;
        border-radius: 10px;
    }


}

@media (min-width: 728px) {
    .simulation-features {
        max-width: 400px;
        border-radius: 20px;
        background-color: RGB(242,242,242);
        color: black;
    }
    .simulation-box {
        max-width: 400px;
    }
    .advanceButton {
        background-color: RGB(51,141,205);
        color: #fff;
        font-weight: 500;
        display: inline-block;
        border: none;
        height: 50px;
        width: 400px;
        line-height: 46px;
        text-align: center;
        border-radius: 10px;
    }

    .periodButton {
        background-color: RGB(51,141,205);
        color: #fff;
        font-weight: 500;
        display: inline-block;
        border: none;
        height: 50px;
        line-height: 46px;
        text-align: center;
        border-radius: 10px;
        margin-left: 0px;
        max-width: 50px;
    }

}

.periodButton {
    background-color: RGB(51,141,205);
    color: #fff;
    font-weight: 500;
    display: inline-block;
    border: none;
    height: 50px;
    line-height: 46px;
    text-align: center;
    border-radius: 10px;
    margin-left: 0px;
    max-width: 150px;
}

.text-blue {
    color: #384268;
}

*:focus {
    outline: none !important;
}
